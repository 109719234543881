<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Dashboard</h2>
    </template>
    <template #page-content>
      <div class="columns is-multiline dashboard__margin">
        <div class="column is-3">
          <r-card>
            <div class="is-12 has-text-weight-black is-size-5">Users</div>
            <div class="columns is-multiline">
              <b-icon
                icon="account-plus"
                class="dashboard__user-icon"
                size="is-medium"
              ></b-icon>
              <div class="column is-8 has-text-weight-bold is-size-2 pt-4">
                {{ dashboardInfo ? dashboardInfo.users : '-' }}
              </div>
            </div>
          </r-card>
        </div>
        <div class="column is-3">
          <r-card>
            <div class="is-12 has-text-weight-black is-size-5">Companies</div>
            <div class="columns is-multiline">
              <b-icon
                icon="briefcase"
                class="dashboard__companies-icon"
                size="is-medium"
              ></b-icon>
              <div class="column is-8 has-text-weight-bold is-size-2 pt-4">
                {{ dashboardInfo ? dashboardInfo.companies : '-' }}
              </div>
            </div>
          </r-card>
        </div>
        <div class="column is-3">
          <r-card class="growth-card">
            <div class="is-12 has-text-weight-black is-size-5">
              Growth Company
            </div>
            <div class="columns is-multiline">
              <img
                src="@/assets/images/GrowthUser.svg"
                class="dashboard__growth-user-icon"
              />
              <div class="column is-8 has-text-weight-bold is-size-2">
                {{ percentage ? percentage + '%' : '-%' }}
              </div>
            </div>
          </r-card>
        </div>
        <div class="column is-3">
          <r-card>
            <div class="is-12 has-text-weight-black is-size-5">
              Projection Income (IDR)
            </div>
            <div class="columns is-multiline">
              <b-icon
                icon="wallet-giftcard"
                class="dashboard__total-income-icon"
                size="is-medium"
              ></b-icon>
              <div class="column is-8 has-text-weight-bold is-size-2 pt-4">
                {{ dashboardInfo ? dashboardInfo.projectionIncome : '-' }}
              </div>
            </div>
          </r-card>
        </div>
        <div class="column is-9">
          <r-card class="dashboard__card">
            <div class="is-12 has-text-weight-black is-size-5">
              Company Graphic
            </div>
            <div class="is-12">
              <company-chart class="dashboard__graph"></company-chart>
            </div>
          </r-card>
        </div>
        <div class="column is-3">
          <r-card class="dashboard__card">
            <div class="columns is-multiline mb-0">
              <div class="column is-8 has-text-weight-black is-size-5">
                Latest Company
              </div>
            </div>
            <div class="column is-12 dashboard__card__list">
              <div
                class="columns is-multiline mt-0"
                v-for="(item, index) in latestCompany"
                :key="index"
              >
                <div class="is-1">
                  <div class="company-profile-pic pl-0 pr-0">
                    <img src="@/assets/images/location_city.svg" />
                  </div>
                </div>
                <div class="column pt-0 pl-0">
                  <div class="column is-12 company-component__name pt-0">
                    {{
                      item ? textSubString(item.companyName, 15, '...') : '-'
                    }}
                  </div>
                  <div class="columns is-multiple company-component__member">
                    <div class="column is-1 ml-3">
                      <b-icon
                        icon="account"
                        class="is-small company-component__icon"
                      ></b-icon>
                    </div>
                    <div class="column is-11 company-component__icon">
                      {{ item ? item.totalUsers + ' people' : '-' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </r-card>
        </div>
        <div class="column is-9">
          <r-card>
            <div class="is-12 has-text-weight-black is-size-5">
              User Statistics
            </div>
            <div class="is-12">
              <user-chart class="dashboard__graph"></user-chart>
            </div>
          </r-card>
        </div>
        <div class="column is-3">
          <r-card class="dashboard__card">
            <div class="columns is-multiline mb-0">
              <div class="column is-9 has-text-weight-black is-size-5">
                Latest Drop Company
              </div>
            </div>
            <div class="column is-12 dashboard__card__list">
              <div
                class="columns is-multiline mt-0"
                v-for="(item2, index2) in latestDropCompany"
                :key="index2"
              >
                <div class="is-2">
                  <div class="company-profile-pic pl-0 pr-0">
                    <img src="@/assets/images/location_city.svg" />
                  </div>
                </div>
                <div class="column pt-0 pl-0">
                  <div class="column is-12 company-component__name pt-0">
                    {{
                      item2 ? textSubString(item2.companyName, 15, '...') : '-'
                    }}
                  </div>
                  <div class="columns is-multiple company-component__member">
                    <div class="column is-1 ml-3">
                      <b-icon
                        icon="account"
                        class="is-small company-component__icon"
                      ></b-icon>
                    </div>
                    <div class="column is-11 company-component__icon">
                      {{ item2 ? item2.totalUsers + ' people' : '-' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </r-card>
        </div>
        <div class="column is-9">
          <div class="columns is-multiline">
            <div
              class="column is-4"
              v-for="(item3, index3) in activeCompanyPackage"
              :key="index3"
            >
              <r-card class="dashboard__card">
                <div class="columns is-multiline mb-0">
                  <div class="column is-5 package-name">
                    {{ item3 ? item3.packageName : '-' }}
                  </div>
                  <div class="column is-7 is-flex is-justify-content-center">
                    <b-tag
                      size="is-medium"
                      type="is-info is-light"
                      class="package-component-tag"
                    >
                      <b-icon icon="domain" class="is-medium"></b-icon>
                      <span class="is-justify-content-center is-size-6">
                        {{
                          item3
                            ? item3.totalCompany === 1
                              ? item3.totalCompany + ' company'
                              : item3.totalCompany + ' companies'
                            : '-'
                        }}
                      </span>
                    </b-tag>
                  </div>
                </div>
                <div
                  class="columns is-multiline mt-0"
                  v-for="(item4, index4) in item3 ? item3.companyName : null"
                  :key="index4"
                >
                  <div class="column is-2">
                    <div class="company-profile-pic pl-0 pr-0">
                      <img src="@/assets/images/location_city.svg" />
                    </div>
                  </div>
                  <div class="column is-10 pl-4">
                    <div class="column is-12 company-component__name pt-0">
                      {{ item4 ? textSubString(item4, 15, '...') : '-' }}
                    </div>
                    <div class="columns is-multiple company-component__member">
                      <div class="column is-1 ml-3">
                        <b-icon
                          icon="account-multiple"
                          class="is-small company-component__icon"
                        ></b-icon>
                      </div>
                      <div class="column is-11 company-component__icon">
                        {{ item3.totalUsers[index4] }}
                      </div>
                    </div>
                  </div>
                </div>
              </r-card>
            </div>
          </div>
        </div>

        <div class="column is-3">
          <r-card class="dashboard__card">
            <div class="columns is-multiline mb-0">
              <div class="column is-9 has-text-weight-black is-size-5">
                Inactive Company
              </div>
            </div>
            <div class="column is-12 dashboard__card__list">
              <div
                class="columns is-multiline mt-0"
                v-for="(item5, index5) in inactiveCompany"
                :key="index5"
              >
                <div class="is-2">
                  <div class="company-profile-pic pl-0 pr-0">
                    <img src="@/assets/images/location_city.svg" />
                  </div>
                </div>
                <div class="column pt-0 pl-0">
                  <div class="column is-12 company-component__name pt-0">
                    {{
                      item5 ? textSubString(item5.companyName, 15, '...') : '-'
                    }}
                  </div>
                  <div class="columns is-multiple company-component__member">
                    <div class="column is-1 ml-3">
                      <b-icon
                        icon="account"
                        class="is-small company-component__icon"
                      ></b-icon>
                    </div>
                    <div class="column is-11 company-component__icon">
                      {{ item5 ? item5.totalUsers + ' people' : '-' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </r-card>
        </div>
      </div>
    </template>
  </r-page>
</template>
<script>
import ApiService from '@/services/common/api.service'
import CompanyChart from '@/components/CompanyChart.vue'
import UserChart from '@/components/UserChart.vue'

export default {
  components: { CompanyChart, UserChart },
  data() {
    return {
      loaded: false,
      growthCompany: {
        dropCompany: null,
        month: null,
        newCompany: null,
        year: null,
      },

      percentage: null,

      latestCompany: {
        companyId: null,
        companyName: '',
        packageId: null,
        packageName: '',
        active_date: null,
        total_users: null,
      },

      dashboardInfo: {
        companies: null,
        projectionIncome: '',
        users: null,
      },

      latestDropCompany: {
        companyId: null,
        companyName: '',
        packageId: null,
        packageName: '',
        active_date: null,
        total_users: null,
      },

      inactiveCompany: {
        companyId: null,
        companyName: null,
        createdAt: null,
        total_users: null,
      },

      activeCompanyPackage: {
        packageId: null,
        packageName: '',
        totalCompany: null,
        companyName: '',
        totalUsers: '',
      },
    }
  },

  methods: {
    async getLatestCompany() {
      await ApiService.get('/auth/package/latest').then((response) => {
        this.latestCompany = response.data.data
      })
    },

    async getLatestDropCompany() {
      await ApiService.get('/auth/package/latest-drop').then((response) => {
        this.latestDropCompany = response.data.data
      })
    },

    async getInactiveCompany() {
      await ApiService.get('/auth/dashboard/inactive-company').then(
        (response) => {
          this.inactiveCompany = response.data
        }
      )
    },

    async getDashboardGeneralInfo() {
      await ApiService.get('/auth/dashboard/info').then((response) => {
        this.dashboardInfo = response.data
      })
    },

    async getCompanyActivePerPackage() {
      await ApiService.get('/auth/package/segmentation').then((response) => {
        response.data.data.forEach((element) => {
          element.companyName
            ? (element.companyName = element.companyName.split('|'))
            : null
          element.totalUsers
            ? (element.totalUsers = element.totalUsers.split('|'))
            : null
        })
        this.activeCompanyPackage = response.data.data
      })
    },

    async getGrowthCompany() {
      await ApiService.get('/auth/dashboard/graphic').then((response) => {
        this.growthCompany = response.data
        if (
          this.growthCompany[10].newCompany === 0 ||
          this.growthCompany[11].newCompany === 0
        ) {
          this.percentage = 0
        } else {
          this.percentage =
            ((this.growthCompany[11].newCompany -
              this.growthCompany[10].newCompany) /
              this.growthCompany[10].newCompany) *
            100
        }
      })
    },
  },

  mounted() {
    this.getLatestCompany()
    this.getLatestDropCompany()
    this.getCompanyActivePerPackage()
    this.getDashboardGeneralInfo()
    this.getGrowthCompany()
    this.getInactiveCompany()
  },
}
</script>
