<script>
import { Bar } from 'vue-chartjs'
import ApiService from '@/services/common/api.service'
import moment from 'moment'

export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dataGraph: {
        dropUsers: null,
        month: null,
        newUsers: null,
        year: null,
      },
      loaded: false,
      options: {
        legend: {
          position: 'bottom',
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },

  async mounted() {
    try {
      await ApiService.get('/auth/dashboard/user-statistic').then(
        (response) => {
          this.dataGraph = response.data
        }
      )
      this.loaded = true
    } catch (e) {
      console.error(e)
    }

    let months = this.dataGraph.map((element) =>
      moment()
        .month(element.month - 1, 'month')
        .startOf('month')
        .format('MMMM')
    )
    let newUsers = this.dataGraph.map((element) => element.newUsers)
    let dropUsers = this.dataGraph.map((element) => element.dropUsers)
    this.renderChart(
      {
        labels: months,
        datasets: [
          {
            label: 'New Users',
            backgroundColor: '#4472C4',
            data: newUsers,
          },
          {
            label: 'Drop Users',
            backgroundColor: '#ED7D31',
            data: dropUsers,
          },
        ],
      },
      this.options
    )
  },
}
</script>
